export interface EventRecordDto {
  id?: number;
  eventTrackerSessionId?: string;
  currency?: string;
  langPrefix?: string;
  pageTitle?: string;
  keywords: string[];
  eventName?: string;
  iPAddress?: string;
  eventData?: string;
  ecryptedEventData?: string;
  checksum: string;
  createdDate?: string;
}

export interface EventRecordSessionDto {
  id: string;
  createdDate: string;
  eventsCount?: number;
  lastEventCreatedDate?: string;
  isProductInCart?: boolean;
  isCartFormEdited?: boolean;
  orderFailed?: boolean;
  orderSucceded?: boolean;
}

export enum EventTrackerSessionFilterEnum {
  IsLive = 1,
  ProductsInCart = 2,
  CartFormTouched = 3,
  PaymentFailed = 4,
  OrderFailed = 5
}