import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Form from "../../../../../../../../libs/forms/Form/Form";
import TextInput from "../../../../../../../../libs/forms/TextBox/TextInput";
import { CustomerDto, OrderDto } from "../../../../../../../../api/order/order.dto";
import Validators from "../../../../../../../../libs/forms/validators/validators";
import Col from "../../../../../../../../libs/grid/col";
import Row from "../../../../../../../../libs/grid/row";

interface CustomerFormProps {
  data: OrderDto;
}

const CustomerDeliveryForm: React.FC<CustomerFormProps> = (props) => {
  const { data } = props;
  const {
    register,
    reset,
    formState: { errors },
  } = useForm<CustomerDto>({ defaultValues: {} });

  useEffect(() => {
    if (data && data.customerCopy) reset({ ...data.customerCopy } as CustomerDto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Form>
        <div className="form-child form-child-wrapper">
          {/* <h3 className="title form-child">Package recipient</h3>
          <Row gap={0} align="center">
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="name"
                label="Name"
                validation_msg={errors?.shippingAddress?.name?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.name", Validators.required),
                }}
              />
              <TextInput
                id="surname"
                label="Surname"
                required
                validation_msg={errors?.shippingAddress?.surname?.message}
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.surname", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="email"
                label="Email"
                required
                validation_msg={errors?.shippingAddress?.email?.message}
                inputProps={{
                  type: "email",
                  disabled: true,
                  ...register("shippingAddress.email", Validators.email),
                }}
              />
              <TextInput
                id="phone"
                label="Phone"
                required
                validation_msg={errors?.shippingAddress?.phone?.message}
                inputProps={{
                  maxLength: 20,
                  disabled: true,
                  ...register("shippingAddress.phone", Validators.required),
                }}
              />
            </Col>
          </Row> */}
          <h3 className="title form-child">Delivery address</h3>
          <Row gap={0}>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="country"
                label="Country"
                validation_msg={errors?.shippingAddress?.country?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.country", Validators.required),
                }}
              />
              <TextInput
                id="street"
                label="Street"
                required
                validation_msg={errors?.shippingAddress?.street?.message}
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.street", Validators.required),
                }}
              />
              <TextInput
                id="homenumber"
                label="Home number"
                validation_msg={errors?.shippingAddress?.homenumber?.message}
                required
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.homenumber", Validators.required),
                }}
              />
            </Col>
            <Col size={[6, 6, 12, 12]}>
              <TextInput
                id="city"
                label="City"
                required
                validation_msg={errors?.shippingAddress?.city?.message}
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.city", Validators.required),
                }}
              />

              <TextInput
                id="postalcode"
                label="Postal code"
                required
                validation_msg={errors?.shippingAddress?.postalcode?.message}
                inputProps={{
                  maxLength: 6,
                  disabled: true,
                  ...register("shippingAddress.postalcode", Validators.required),
                }}
              />
              <TextInput
                id="flatnumber"
                label="Flat number"
                validation_msg={errors?.shippingAddress?.flatnumber?.message}
                inputProps={{
                  disabled: true,
                  ...register("shippingAddress.flatnumber"),
                }}
              />
            </Col>
          </Row>
          {/* <h3 className="title form-child">Other informations</h3>
          <Row gap={0}>
            <Col size={12}>
              <TextInput
                id="notepad"
                label="Message for the seller"
                inputProps={{
                  type: "textarea",
                  disabled: true,
                  ...register("shippingAddress.notepad"),
                }}
              />
            </Col>
          </Row> */}
        </div>
      </Form>
    </>
  );
};

export default CustomerDeliveryForm;
