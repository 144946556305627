import { generatePath } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import useEffectAfterMounted from "../../../../../../../../features/useEffectAfterMounted";
import GenericList, { NumberStringTuple } from "../../../../../../../../libs/generic-list/generic-list";
import { useAppDispatch } from "../../../../../../../../redux/store";
import { RoutePath } from "../../../../../../../../route-paths";
import Menu, { MenuItemType } from "../../../../../../../components/Menu/menu";
import { showSlideOverlay } from "../../../../../../../components/router-transition/routerTransitionSlice";
import Pagination from "src/app/components/Pagination/Pagination";
import { BasicColumnFilterDto, StandardFilterDto } from "src/libs/filters/filters.dto";
import { useState } from "react";
import { PaginationFilterDto } from "src/app/components/Pagination/pagination.dto";
import { ArrayResult } from "src/api/client";
import Filters from "src/libs/filters/FiltersForm";
import Validators from "src/libs/forms/validators/validators";
import TopLink from "src/app/components/top-link/top-link";
import { EventRecordSessionDto, EventTrackerSessionFilterEnum } from "src/api/eventTracker/eventTracker.dto";
import DateHelper from "src/features/date-helper";

interface EventsSessionsListProps {}

const EventsSessionsList = (props: EventsSessionsListProps) => {
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 30, skip: 0 });

  const { data, status, call } = useQuery<ArrayResult<EventRecordSessionDto>, BasicColumnFilterDto>("EventTracker/ListSessions", [
    Api.EventTracker.ListSessions,
    { ...filters, ...pagination },
  ]);

  // const { status: delStatus, call: callDeleteRoot } = useQuery<void, number>("eventsSession/deleteRoot", [Api.EventsSession.DeleteRoot], { callOnLoad: false });

  const dispatch = useAppDispatch();

  useEffectAfterMounted(() => {
    call({ ...filters, ...pagination });
  }, [filters, pagination]);

  // useEffectAfterMounted(() => {
  //   if (delStatus === "finished") {
  //     ToastsStore.success("EventsSession has been deleted");
  //     call();
  //   }
  // }, [delStatus]);

  // const handleDeleteRoot = (articleRoot: ArticleRootDto) => {
  //   Confirm(`Are you sure you want to delete eventsSession ${articleRoot.rootName}?`, () => callDeleteRoot(articleRoot.id));
  // };

  const standardCols: NumberStringTuple[] = [
    [
      "Id",
      "",
      4,
      (x: EventRecordSessionDto) => (
        <TopLink to={generatePath(RoutePath.PANEL_EVENTTRACKER_SESSIONS_EDIT, { id: x.id?.toString() })} className="primary">
          {x.id}
        </TopLink>
      ),
    ],
    ["Start at", "", 2, (x: EventRecordSessionDto) => DateHelper.ConvertZone__API_to_UI__formated(x.createdDate)],
    ["Last event", "", 2, (x: EventRecordSessionDto) => DateHelper.ConvertZone__API_to_UI__formated(x.lastEventCreatedDate)],
    ["Events count", "", 2, (x: EventRecordSessionDto) => x.eventsCount],
    [
      "Status",
      "",
      4,
      (x: EventRecordSessionDto) => {
        const apiDateObject = new Date(x.lastEventCreatedDate + "z");
        const now = new Date();
        const deltaTimeMin = (now.getTime() - apiDateObject.getTime()) / (60 * 1000);

        return (
          <>
            <span title="Is live?">{deltaTimeMin < 5 ? "🟢" : deltaTimeMin < 30 ? "🟡" : "⚪"}</span>
            &nbsp;
            <span style={x.isProductInCart ? {} : { opacity: 0.6, filter: "grayscale(1)" }} title="Products in cart">
              🛍️
            </span>
            &nbsp;
            <span style={x.isCartFormEdited ? {} : { opacity: 0.6, filter: "grayscale(1)" }} title="Customer form touched">
              📜
            </span>
            &nbsp;
            <span title="Order status" style={x.orderSucceded || x.orderFailed ? {} : { opacity: 0.6, filter: "grayscale(1)" }}>
              {x.orderSucceded ? "✔️" : x.orderFailed ? "❌" : "⏳"}
            </span>
          </>
        );
      },
    ],
  ];

  const standardActions = (actionsItems: (item: EventRecordSessionDto) => Array<MenuItemType>): NumberStringTuple => [
    "",
    "",
    1,
    (item: EventRecordSessionDto) => <Menu header="Options" items={actionsItems(item)} />,
  ];

  const actionsItems__edit = (item: EventRecordSessionDto) => {
    return {
      label: "Edit",
      onAction: () => dispatch(showSlideOverlay(generatePath(RoutePath.PANEL_EVENTTRACKER_SESSIONS_EDIT, { id: item.id?.toString() }))),
    };
  };
  // const actionsItems__delete = (item: EventRecordSessionDto) => {
  //   return { label: "Delete", onAction: () => handleDeleteRoot(item) };
  // };

  const standardActionsItems = (item: EventRecordSessionDto): Array<MenuItemType> => {
    return [actionsItems__edit(item)]; //, actionsItems__delete(item)];
  };

  return (
    <>
      <Filters
        onSubmit={setFilters}
        setPagination={setPagination}
        status={status}
        fields={[
          {
            field: "searchByColumn",
            component: "SelectBox",
            label: "Column",
            options: [
              { id: "status", label: "Status" },
              // { id: "role", label: "Rola" },
            ],
            conditionalFields: [
              // {
              //   basedOn: "searchByColumn",
              //   condition: (x: any) => x === "status",
              //   fields: [{ field: "searchText", component: "TextBox", placeholder: "Wyszukaj", label: "Column contents", validators: Validators.required }],
              // },
              {
                basedOn: "searchByColumn",
                condition: (x: any) => x === "status",
                fields: [
                  {
                    field: "searchEnum", //"searchEnum",
                    component: "SelectBox",
                    label: "Wyszukaj",
                    validators: Validators.required,
                    options: [
                      {
                        id: EventTrackerSessionFilterEnum.IsLive,
                        label: "Is live",
                      },
                      {
                        id: EventTrackerSessionFilterEnum.ProductsInCart,
                        label: "Products in cart",
                      },
                      {
                        id: EventTrackerSessionFilterEnum.CartFormTouched,
                        label: "Cart form touched",
                      },
                      {
                        id: EventTrackerSessionFilterEnum.PaymentFailed,
                        label: "Payment failed",
                      },
                      {
                        id: EventTrackerSessionFilterEnum.OrderFailed,
                        label: "Order failed",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ]}
      />

      <GenericList //
        status={status}
        data={data?.list}
        cols={[...standardCols]}
        actions={standardActions(standardActionsItems)}
      />
      <Pagination
        disableScroll
        totalCount={data?.totalCount}
        itemsPerPage={10}
        take={pagination.take!}
        setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
      />
    </>
  );
};

export default EventsSessionsList;
