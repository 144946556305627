import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import EventsSessionsList from "./eventsSessionsList/eventsSessionsList";


const EventsSessionsListPage = () => {
  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_EVENTTRACKER_SESSIONS_LIST} title="List" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Events Tracker</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <div className="d-flex pb-3">
                <h2 className="title mr-auto">List of sessions</h2>
              </div>
              <hr />
              <EventsSessionsList />
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default EventsSessionsListPage;
