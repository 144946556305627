import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../api/Api";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../components/Tile/Tile";
import { RoutePath } from "../../../../../../../route-paths";
import { EventRecordDto, EventRecordSessionDto } from "src/api/eventTracker/eventTracker.dto";
import { ArrayResult } from "src/api/client";
import { BasicColumnFilterDto, StandardFilterDto } from "src/libs/filters/filters.dto";
import { useState } from "react";
import { PaginationFilterDto } from "src/app/components/Pagination/pagination.dto";
import GenericList, { NumberStringTuple } from "src/libs/generic-list/generic-list";
import DateHelper from "src/features/date-helper";
import TextBlock from "src/libs/text-block/text-block";
import { xorEncryptDecrypt } from "src/features/unsafe-encrypt-decrypt";
import { html } from "src/features/html";
import { syntaxHighlight } from "src/features/jsonSyntax";
import Pagination from "src/app/components/Pagination/Pagination";
import useEffectAfterMounted from "src/features/useEffectAfterMounted";

const EventSessionEditPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState<StandardFilterDto>({});
  const [pagination, setPagination] = useState<PaginationFilterDto>({ take: 50, skip: 0 });
  const [openRows, setOpenRows] = useState([] as number[]);

  const { id = "" } = useParams<{ id: string }>();
  const { data: eventsSession } = useQuery<EventRecordSessionDto, string>("EventTracker/GetSession", [Api.EventTracker.GetSession, id], {
    statusResetDelay: 1000,
  });

  const {
    data: eventsData,
    status: eventSstatus,
    call: eventsCall,
  } = useQuery<ArrayResult<EventRecordDto>, { filters?: BasicColumnFilterDto; sessionId: string }>("EventTracker/ListEvents", [
    Api.EventTracker.ListEvents,
    { ...filters, ...pagination, sessionId: id },
  ]);

  useEffectAfterMounted(() => {
    eventsCall({ ...filters, ...pagination, sessionId: id });
  }, [filters, pagination]);

  const addEmojiIfNeeded = (x: string) => {
    if (x === "Cart edit items") return "🛍️ ";
    else if (x === "Cart edit form") return "📜 ";
    else if (x === "Init Session") return "🆕 ";
    else if (x === "Change currency") return "💱 ";
    else if (x === "Change language") return "🌐 ";
    else if (x === "Change page") return "📄 ";
    else if (x === "Payment failed") return "❌ ";
    else if (x === "Order failed") return "❌ ";
    else return "⬆️ ";
  };

  const standardCols: NumberStringTuple[] = [
    ["Id", "id", 1],
    ["Event", "eventName", 3, (x) => `${addEmojiIfNeeded(x)}${x}`],
    ["Created at", "", 3, (x: EventRecordDto) => DateHelper.ConvertZone__API_to_UI__formated(x.createdDate)],
    ["Page", "pageTitle", 4],
    [
      "Details",
      "",
      1,
      (x: EventRecordDto) => (
        <>
          {openRows.findIndex((state) => x.id === state) !== -1 ? (
            <div onClick={() => setOpenRows((state) => state.filter((state) => state !== x.id))} className="pointer">
              🔎
            </div> //ukryj
          ) : (
            <div onClick={() => setOpenRows((state) => [...state, x.id ?? -1])} className="pointer">
              🔍
            </div> // pokaż
          )}
        </>
      ),
    ],
  ];

  const getDeltaTimeMin = (eventsSession: EventRecordSessionDto) => {
    const apiDateObject = new Date(eventsSession.lastEventCreatedDate + "z");
    const now = new Date();
    const deltaTimeMin = (now.getTime() - apiDateObject.getTime()) / (60 * 1000);
    return deltaTimeMin;
  };

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_EVENTTRACKER_SESSIONS_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_EVENTTRACKER_SESSIONS_EDIT, { id: id })} title="Edit eventsSession" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Analyse event tracker session</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 10, 12, 12]}>
            <Tile>
              <h2 className="title">Base informations</h2>
              {eventsSession ? (
                <TextBlock>
                  <p>CreatedAt: {DateHelper.ConvertZone__API_to_UI__formated(eventsSession.createdDate)}</p>
                  <p>ID: {eventsSession.id}</p>
                  <p>
                    <span title="Is live?">{getDeltaTimeMin(eventsSession) < 5 ? "🟢" : getDeltaTimeMin(eventsSession) < 30 ? "🟡" : "⚪"}</span> Is live?
                    <br />
                    <span style={eventsSession.isProductInCart ? {} : { opacity: 0.6, filter: "grayscale(1)" }} title="Products in cart">
                      🛍️
                    </span>{" "}
                    Products in cart
                    <br />
                    <span style={eventsSession.isCartFormEdited ? {} : { opacity: 0.6, filter: "grayscale(1)" }} title="Customer form touched">
                      📜
                    </span>{" "}
                    Customer form touched
                    <br />
                    <span title="Order status" style={eventsSession.orderSucceded || eventsSession.orderFailed ? {} : { opacity: 0.6, filter: "grayscale(1)" }}>
                      {eventsSession.orderSucceded ? "✔️" : eventsSession.orderFailed ? "❌" : "⏳"}
                    </span>{" "}
                    Order status
                  </p>
                </TextBlock>
              ) : null}

              {/* JSON.stringify(eventsSession) : null} */}
              {/* <ArticleEditForm data={articleRoot} /> */}
            </Tile>
            <br />
            <Tile>
              <h2 className="title">Events</h2>
              <GenericList //
                status={eventSstatus}
                data={eventsData?.list}
                cols={[...standardCols]}
                rowChildren={(x: EventRecordDto) => {
                  return openRows.findIndex((state) => x.id === state) !== -1 ? (
                    <div className="p-2 pr-4" style={{ wordWrap: "break-word" }}>
                      <pre>
                        {html(
                          syntaxHighlight(
                            JSON.stringify(
                              {
                                ...x,
                                eventData: x.eventData ? JSON.parse(x.eventData ?? "{}") : null,
                                ecryptedEventData: x.ecryptedEventData ? JSON.parse(xorEncryptDecrypt(x.ecryptedEventData, "klucz") ?? "{}") : null,
                              },
                              null,
                              2
                            )
                          )
                        )}
                      </pre>
                    </div>
                  ) : null;
                }}
                // actions={standardActions(standardActionsItems)}
              />
              <Pagination
                disableScroll
                totalCount={eventsData?.totalCount}
                itemsPerPage={50}
                take={pagination.take!}
                setNewSkip={(newSkip: number) => setPagination({ ...pagination, skip: newSkip })}
              />
              {/* {eventsData ? JSON.stringify(eventsData) : null} */}
              {/* <ArticleEditForm data={articleRoot} /> */}
            </Tile>
          </Col>
        </PanelRow>
      </PanelGrid>
    </div>
  );
};

export default EventSessionEditPage;
