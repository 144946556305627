import { Navigate, Outlet } from "react-router-dom";
import { RoutePath } from "../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../components/Breadcrumbs/BreadcrumbsItem";
import ExactPathSwitch from "../../../../../components/ExactPathSwitch/ExactPathSwitch";

const ArticlesLayout = () => {

  return (
    <>
      <BreadcrumbsItem to={RoutePath.PANEL_EVENTTRACKER_SESSIONS} title="Events Sessions" isNotLink={true} />
      <ExactPathSwitch
        path={RoutePath.PANEL_EVENTTRACKER_SESSIONS}
        if_exact={<Navigate to={RoutePath.PANEL_EVENTTRACKER_SESSIONS_LIST} />}
        else={<Outlet />}
      />
    </>
  );
};

export default ArticlesLayout;
