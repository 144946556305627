import { BasicColumnFilterDto } from "src/libs/filters/filters.dto";
import Client, { ArrayResult } from "../client";
import { EventRecordDto, EventRecordSessionDto } from "./eventTracker.dto";

class EventTracker {
  static async GetSession(id: string): Promise<EventRecordSessionDto> {
    return Client.get("EventTracker/GetSession", { id: id });
  }
  static async ListSessions(filters?: BasicColumnFilterDto): Promise<ArrayResult<EventRecordSessionDto>> {
    return Client.get("EventTracker/ListSessions", { ...filters });
  }
  static async GetEvent(id: number): Promise<EventRecordDto> {
    return Client.get("EventTracker/GetEvent", { id: id });
  }
  static async ListEvents({ filters, sessionId, ...rest }: { filters?: BasicColumnFilterDto; sessionId: string }): Promise<ArrayResult<EventRecordDto>> {
    return Client.get("EventTracker/ListEvents", { ...filters, ...rest, sessionId: sessionId });
  }
}

export default EventTracker;
